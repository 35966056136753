// eslint-disable-next-line no-unused-vars
import Alert from "bootstrap/js/dist/alert";
// eslint-disable-next-line no-unused-vars
import Collapse from "bootstrap/js/dist/collapse";
// eslint-disable-next-line no-unused-vars
import Dropdown from "bootstrap/js/dist/dropdown";
import htmx from "htmx.org";

import config from "./config";
import { addMultipleSubmitProtection } from "./utils";
import { gapiOnload, initUpdatePreferredLanguage } from "./translation";

/**
 * Run all site-wide code.
 */
export function initSiteWide() {
  if (!Object.prototype.hasOwnProperty.call(window, "htmx")) {
    window.htmx = htmx;
  }

  // Initialize form submit protection for all forms currently on the page
  document.querySelectorAll("form").forEach((form) => {
    addMultipleSubmitProtection(form);
  });

  // Initialize form submit protection for any forms added via htmx
  document.body.addEventListener("htmx:afterSettle", (event) => {
    if (
      event.detail &&
      event.detail.elt &&
      (event.detail.elt.querySelector("form") || event.detail.elt.tagName === "FORM")
    ) {
      addMultipleSubmitProtection(
        event.detail.elt.tagName === "FORM"
          ? event.detail.elt
          : event.detail.elt.querySelector("form"),
      );
    }
  });

  if (config.isStudent) {
    window.gapiOnload = gapiOnload;
    config.whenReadyFunctions.push(initUpdatePreferredLanguage);
  }
}

/**
 * Run all site-wide code that we want to execute after all of our scripts have loaded.
 */
export function initSiteWideOnReady() {
  config.whenReadyFunctions.forEach((func) => func());
}
