/**
 * Add mulitple-submit protection to a given form element.
 *
 * This function attaches a submit listener that, upon firing, replaces all submit
 * buttons associated with a form with disabled dummy buttons.
 *
 * This function also registers listeners for the `submit-protection-reset` event
 * which removes submit protection and puts the restores the original buttons.
 *
 * @param{HTMLFormElement} form - the form element to add multiple submit protection to
 */
export function addMultipleSubmitProtection(form) {
  if (form.dataset.noSubmitProtection) {
    return;
  }

  const getSubmitButtons = () => [
    ...form.querySelectorAll('[type="submit"]'),
    ...document.querySelectorAll(`[type="submit"][form="${form.getAttribute("id")}"]`),
  ];

  const submitButtons = getSubmitButtons();

  submitButtons.forEach((submitButton) => {
    submitButton.dataset.clicked = false;
    submitButton.addEventListener("click", (event) => {
      event.currentTarget.dataset.clicked = true;
    });
  });

  const submitHandler = async () => {
    if (form.dataset.submitProtectActive) {
      return;
    }

    form.dataset.submitProtectActive = "1";

    // NOTE: Bump this event so the event that sets the `clicked` dataset attribute
    // will fire first.
    await sleep(10);

    const currentSubmitButtons = getSubmitButtons();
    currentSubmitButtons.forEach((element) => {
      swapButtonForDummy(element);
    });

    // Bind an (optional) function to event "submit-protection-reset"
    [
      "submit-protection-reset",
      "htmx:responseError",
      "htmx:sendError",
      "htmx:timeout",
      "htmx:afterRequest",
    ].forEach((evtName) => {
      form.addEventListener(evtName, () => {
        if (!document.body.contains(form)) {
          return;
        }

        const submitButtonsForCancel = getSubmitButtons();
        const dummyButtons = submitButtonsForCancel.filter(
          (element) => element.dataset.isDummy === "true",
        );
        const nonDummyButtons = submitButtonsForCancel.filter(
          (element) => element.dataset.isDummy !== "true",
        );
        dummyButtons.forEach((dummyButton) => {
          dummyButton.remove();
        });
        nonDummyButtons.forEach((nonDummyButton) => {
          nonDummyButton.classList.remove("d-none");
        });

        delete form.dataset.submitProtectActive;
      });
    });

    currentSubmitButtons.forEach((currentSubmitButton) => {
      currentSubmitButton.dataset.clicked = false;
    });
  };

  form.addEventListener("submit", submitHandler);
  form.addEventListener("htmx:beforeSend", (event) => {
    if (event.detail.requestConfig.verb !== "get") {
      submitHandler();
    }
  });
}

/**
 * Return a Promise that resolves to a string representing the end of a CSS animation as
 * defined within sass/_animations.scss (this is meant to be used with our copy of
 * Animate.css).
 *
 * Examples:
 *   - Fade an element out after 1.5 seconds: animateCSS(element, 'fadeOut', '1.5s');
 *   - Fade an element out and do something once the animation completes:
 *     animateCSS(element, 'fadeOut').then(() => {
 *       // Do something after the animation
 *     });
 *
 * See https://animate.style/#javascript for the original implementation.
 *
 * @param {Element} element - the element to animate
 * @param {string} animation - the animation name to use (see _animations.scss)
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 * @returns {Promise} A promise representing the animation's execution, which can be awaited
 *     for actions to take post-animation
 */
export function animateCSS(element, animation, duration = undefined) {
  // We create a Promise and return it
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const animationName = `animate__${animation}`;

    if (duration) {
      element.style.setProperty("--animate-duration", duration);
    }

    element.classList.add("animate__animated", animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      element.classList.remove("animate__animated", animationName);
      if (duration) {
        element.style.removeProperty("--animate-duration");
      }
      resolve("Animation ended");
    }

    element.addEventListener("animationend", handleAnimationEnd, {
      once: true,
    });
  });
}

/**
 * Return the order of two given datetimes.
 *
 * NOTE: Meant to be used as a comparison function for functions like
 * Array.prototype.sort().
 *
 * @param {Date} d1
 * @param {Date} d2
 * @returns {number}
 */
export function compareDates(d1, d2) {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Return true if the method given is safe to use without CSRF protection.
 *
 * @param {string} method - An HTTP request method
 * @returns {boolean}
 */
export function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

/**
 * Dispatch an event to a target element.
 *
 * @param {Element} element - The element to send the event to
 * @param {string} type - The event type
 */
export function dispatchEvent(element, type) {
  const event = new Event(type);
  element.dispatchEvent(event);
}

/**
 * Remove a given element's d-none CSS class, and fade it in.
 *
 * @param {HTMLElement} element - an element to fade out
 * @param {number} [delay] - the amount of time to delay the animation by in milliseconds
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 */
export async function fadeIn(element, delay = 0, duration = undefined) {
  await sleep(delay);
  element.classList.remove("d-none");
  await animateCSS(element, "fadeIn", duration);
}

/**
 * Fade a given element out and set its display CSS property to none;
 *
 * @param {HTMLElement} element - an element to fade out
 * @param {number} [delay] - the amount of time to delay the animation by in milliseconds
 * @param {string} [duration] - the duration override of the animation (ex. '500ms')
 */
export async function fadeOut(element, delay = 0, duration = undefined) {
  await sleep(delay);
  await animateCSS(element, "fadeOut", duration);
  element.classList.add("d-none");
}

/**
 * Return an HTMLCollection of elements from a given string of HTML.
 *
 * NOTE: You must use the splat (...) operator to unroll the collection into `.append()`
 * or into a new array to be able to index-access elements (like, say, if there's one
 * top-level element and you want to access it with `[0]`).
 *
 * WARNING: Only use this with non-UGC content!
 *
 * @param {string} html - the HTML content to generate elements from
 * @returns {HTMLCollection} the generated elements
 */
export function generateElements(html) {
  const template = document.createElement("template");
  template.innerHTML = html.trim();
  return template.content.children;
}

/**
 * Reset submit protection for a given element.
 *
 * @param {Element} element - the element to reset submit protection for
 */
export function resetSubmitProtection(element) {
  dispatchEvent(element, "submit-protection-reset");
}

/**
 * Return a Promise that resolves after a given number of milliseconds.
 *
 * @param {number} milliseconds - the amount of time to sleep in milliseconds
 * @returns {Promise} - a Promise representing the sleep action
 */
export function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

/**
 * Given a submit button in a form, make it invisible and place a visible dummy
 * (non-submit) button in its place indicating loading behavior, including a white
 * spinner and customizable loading text.
 *
 * This function will pay attention to the following attributes of the button:
 * - data-submit-loading-text: Overrides the default loading text on the dummy button.
 * - data-submit-remove-loading-text: Removes loading text from the dummy button.
 *
 * @param {HTMLButtonElement} button - the button element
 * @returns {HTMLButtonElement} - the created dummy button element
 */
function swapButtonForDummy(button) {
  let loadingText = button.dataset.submitLoadingText || "Submitting...";
  if (button.dataset.submitRemoveLoadingText) {
    loadingText = "";
  }

  let spinnerSpanClasses = "spinner-border spinner-border-sm";
  if (loadingText !== "") {
    spinnerSpanClasses += `${spinnerSpanClasses} me-2`;
  }

  const dummyButton = button.cloneNode();
  dummyButton.removeAttribute("id");
  dummyButton.removeAttribute("name");
  dummyButton.dataset.isDummy = true;
  dummyButton.setAttribute("disabled", true);

  if (button.dataset.clicked === "true") {
    dummyButton.innerHTML = `<span class="${spinnerSpanClasses}" aria-hidden="true"></span><span role="status">${loadingText}</span>`;
  } else {
    dummyButton.innerHTML = button.innerHTML;
  }

  button.after(dummyButton);

  // Hide the original button.
  button.classList.add("d-none");

  // Return the dummy button
  return dummyButton;
}

/**
 * Swaps the given button for a dummy button and returns a callback to un-swap it when
 * the calling context deems the form submit as complete.
 *
 * Meant to emulate multiple-submit protection for buttons either not associated with a
 * form or buttons that trigger AJAX requests.
 *
 * @param {HTMLButtonElement} button - the button to be swapped while the submit happens
 * @returns {Function} a function that restores the button state to pre-submit
 */
export function swapButtonForDummyWithCallback(button) {
  button.dataset.clicked = true;

  const dummyButton = swapButtonForDummy(button);

  return () => {
    dummyButton.remove();
    button.classList.remove("d-none");
    button.dataset.clicked = false;
  };
}
