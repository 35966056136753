// NOTE: Force webpack to import the font files for Bootstrap Icons
import "bootstrap-icons/font/fonts/bootstrap-icons.woff";
import "bootstrap-icons/font/fonts/bootstrap-icons.woff2";

import "../../styles/core.scss";

import config from "./config";
import { initSiteWide, initSiteWideOnReady } from "./sitewide";

/**
 * Run all site-wide code.
 *
 * @param {object} options - options to merge into the site config object
 */
export function initBase(options) {
  Object.assign(config, options);
  initSiteWide();
}

/**
 * Run all site-wide code that we want to execute after all of our scripts have loaded.
 */
export function initBaseOnReady() {
  initSiteWideOnReady();
}
